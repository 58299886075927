import './Input.scss';
import { useState } from 'react';
import { useNavigate } from "react-router-dom";

const EmailInput = () => {
    const [emailValue, setEmailValue] = useState('');
    const navigate = useNavigate();

    const handleChange = (event) => {
      setEmailValue(event.target.value);
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        // handle search logic here
        console.log(`Searching for ${emailValue}...`);
        navigate('/register', {state: { emailValue }});
    }
  
    return(
        <form className="form" onSubmit={handleSubmit}>
            <div className="EmailInput-container">
                <input className="EmailInput-input" name="email" placeholder="Enter your email" value={emailValue} onChange={handleChange}/>
                <button className="EmailInput-submit" type="submit">Open Account</button>
            </div>
        </form>
    )
};

export default EmailInput;