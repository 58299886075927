import EmailSubscription from './EmailSubscription';
import EmailInput from './Input';
import './Landing.scss';

const Landing = () => {
    
    return(
        <div className='Landing-container'>
			<h1 className='Landing-header'>Banking for ambitious creators</h1>
            <h2 className='Landing-subheader'>Manage your financial operations from a single dashboard</h2>
            {/* <EmailInput></EmailInput> */}
            <section className='Landing-subscription'>
                <h3>Subscribe for the latest Poet Finance news!</h3>
                <EmailSubscription></EmailSubscription>
            </section>
		</div>
    )
};

export default Landing;