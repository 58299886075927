
import React from 'react';
import DataTable from 'react-data-table-component';

const Transactions = (props) => {
    const transactions = props?.transactions?.data?.transactions;
    let USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const columns = [
        {
            name: 'Date',
            selector: row => row.date,
            sortable: true,
        },
        {
            name: 'To/From',
            selector: row => row.merchant_name,
            sortable: true,
        },
        {
            name: 'Amount',
            selector: row => USDollar.format(row.amount),
            sortable: true,
        },
        {
            name: 'Account',
            selector: row => row.account_id,
            sortable: true,
        },
        {
            name: 'Payment Method',
            selector: row => row.payment_channel,
            sortable: true,
        },
    ]
	return (
        <div>
            <h3>Recent transactions</h3>
            {transactions?.length > 0 &&
                <DataTable
                    columns={columns}
                    data={transactions}>
                </DataTable>
            }
        </div>
    );
};
export default Transactions;