import { Amplify } from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import { Authenticator } from '@aws-amplify/ui-react';
import Dashboard from './Dashboard';
import { useLocation } from 'react-router';
import { I18n } from 'aws-amplify/utils';
import './Register.scss'

Amplify.configure({});

export default function Register() {
	const location = useLocation();

	I18n.putVocabulariesForLanguage('en', {
		'Sign In': 'Login', // Tab header
		'Sign in': 'Login', // Button label
		'Login to your account': 'Welcome Back!',
		Username: 'Enter your username', // Username label
		Password: 'Enter your password', // Password label
		'Forgot your password?': 'Reset Password',
	});
	// formFields={formFields}
	return (
		<div className='Register-container'>
			<Authenticator initialState='signUp'>
				{({ signOut, user }) => (
					<Dashboard></Dashboard>
				)}
			</Authenticator>
		</div>
	);
}
