
import React from 'react';
import { Link } from "react-router-dom";
import "@aws-amplify/ui-react/styles.css";
import Landing from './components/Landing';
import './App.scss';

function App({ signOut }) {
	const enableOpenAccount = false;
	return (
		<div className="App">
			<header className="App-header">
				<div className="App-poet"><span className="App-logo"></span>Poet</div>
				{ enableOpenAccount
					? (<div><Link className="App-login" to={`subscribe`}>Login</Link><Link className="App-open-account" to={`subscribe`}>Open Account</Link></div>)
					: ('')
				}
			</header>
			<div className="App-container">
				<Landing></Landing>
			</div>
		</div>
	);
}

export default App;