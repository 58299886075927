
import React, { useState } from 'react';
import { post, get } from 'aws-amplify/api';
import { ConsoleLogger } from 'aws-amplify/utils';
import '@aws-amplify/ui-react/styles.css';
import Link from './PlaidLink';
import {
  Button
} from '@aws-amplify/ui-react';
import Transactions from './TransactionList';
import './Dashboard.scss'

const logger = new ConsoleLogger('Plaid');

function Dashboard({ signOut }) {
	const [linkToken, setLinkToken] = useState('');
	const [transactions, setTransactions] = useState('');

	const getLinkToken = async () => {
		try {
			const { body } = await post({
				apiName: 'linktokenapi',
				path: '/plaidLinkToken'
			}).response;
			const data = await body.json();
			const token = data.link_token;
			setLinkToken(token);
			console.log('tokennn', linkToken);
			getTransactions();
			getAssetReport();
		} catch (err) {
			logger.error('unable to create link token:', err);
		}
	}

	const getTransactions = async () => {
		try {
			const { body } = await get({
				apiName: 'gettransactionsapi',
				path: '/transactions'
			}).response;
			const data = await body.json();
			console.log('transactions', data);
			setTransactions(data);
			return data;
		} catch (err) {
			logger.error('unable to get transations:', err);
		}
	}

	const getAssetReport = async () => {
		try {
			const { body } = await post({
				apiName: 'assetreportapi',
				path: '/assetReport'
			}).response;
			const data = await body.json();
			console.log('assetReport', data);
			// setTransactions(data);
			return data;
		} catch (err) {
			logger.error('unable to get asset report:', err);
		}
	}

	return (
		<div className='Dashboard'>
			<header className='Dashboard-header'>
				Tax Poetic
				<div>
					{
						!linkToken &&
						<button className="Link-bank" onClick={getLinkToken}>Connect a bank account</button>
					}
					<Button onClick={signOut}>Sign Out</Button>
				</div>
			</header>
			<h3>Welcome!</h3>
			<h3>Money movement</h3>
			<div>
				{ transactions && 
					<Transactions transactions={transactions}></Transactions>
				}
			</div>
		</div>
	);
}

export default Dashboard;