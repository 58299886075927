import React from 'react';
import * as ReactDOM from "react-dom/client";
import './index.scss';
import App from './App';
import Register from './components/Register'
import EmailSubscription from './components/EmailSubscription';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import config from './amplifyconfiguration.json';
import {
	createBrowserRouter,
	RouterProvider,
} from "react-router-dom";
Amplify.configure(config);

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "register",
		element: <Register/>,
	},
	{
		path: "subscribe",
		element: <EmailSubscription/>,
	}
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
